import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductPage from "./Pages/ProductPage/ProductPage";
import AboutPage from "./Pages/AboutPage/AboutPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import ComingSoonPage from "./Pages/ComingSoonPage/ComingSoonPage";
import NewsPage from "./Pages/NewsPage/NewsPage";
import LinksPage from "./Pages/LinksPage/LinksPage";
import PrivacyPolicy from "./Pages/LegalPages/PrivacyPage";
import TermsPage from "./Pages/LegalPages/TermsPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router onUpdate={() => window.scrollTo(0, 0)}>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/ProductPage' element={<ProductPage />} />
      <Route path='/AboutPage' element={<AboutPage />} />
      <Route path='/ContactPage' element={<ContactPage />} />
      <Route path='/ComingSoonPage' element={<ComingSoonPage />} />
      <Route path='/NewsPage' element={<NewsPage />} />
      <Route path='/LinksPage' element={<LinksPage />} />
      <Route path='/PrivacyPage' element={<PrivacyPolicy />} />
      <Route path='/TermsPage' element={<TermsPage />} />
    </Routes>
  </Router>
);
