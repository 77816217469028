import React from "react";
import { Link } from "react-router-dom";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";

export default function PrivacyPage() {
  return (
    <div className='relative isolate bg-background'>
      <HeaderMenu />
      <div
        className='absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0'
        aria-hidden='true'
      >
        <div
          className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className='absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0'
        aria-hidden='true'
      >
        <div
          className='aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25'
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <main className='relative isolate overflow-hidden mx-10 px-8 py-24 sm:py-32 lg:overflow-visible '>
        <div className='text-center'>
          <h1 className='text-4xl pb-4 tracking-tight leading-10 font-extrabold text-gray-800 sm:text-5xl sm:leading-none md:text-6xl'>
            Terms of Use
          </h1>
        </div>
        <p>last updated: July 19 2023</p>
        <ul role='list' className='divide-y divide-gray-800'>
          <li>
            <h2 className='text-2xl p-2'>
              The Product Does Not Provide Legal or Medical Service
            </h2>
            <p className='p-4'>
              The contents of the Product, including but not limited to any
              text, agreements, graphics, audio, video, images, tests, surveys,
              guides, reports, software, data or other materials created or
              hosted by Mental Health Connections (collectively, the "Content")
              are for informational and organizational purposes only. Mental
              Health Connections is not a legal service provider, a health care
              provider, or a medical services provider. Always seek the advice
              from the appropriate medical or legal providers with any questions
              you may have regarding the Content.
            </p>
            <p className='p-4'>
              Mental Health Connections does not endorse any specific medical,
              psychological or legal products, tests, procedures, opinions, or
              other information that may be mentioned or linked to on the
              Product. All such references are provided for informational
              purposes only and your reliance on any such information, including
              the Content, is solely at your own risk. You may elect to use the
              Service to assist you in making your own decisions about your
              actions. You agree (i) that we are not a substitute for medical or
              healthcare provider support for an acute mental health issue;
              provided by the Services, and (ii) that you are responsible for
              the content, use, and dissemination of your content or activities
              to others , and (iii) is to be utilized by your own choice and at
              your own risk. As a condition to your use of the Services you
              agree that we are not liable for any damages, injuries, or
              negative results or consequences relating to any recommendations
              provided by the Services.
            </p>
          </li>
        </ul>
      </main>
      <FooterWebsite />
    </div>
  );
}
