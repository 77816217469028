import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import MentalHealthConnectionsLogoGreen from "../Images/sideLogoBlack2.svg";

const navigation = [
  { name: "Product", href: "/ProductPage", id: "Product", current: false },
  { name: "About Us", href: "/AboutPage", id: "AboutUs", current: false },
  { name: "Contact Us", href: "/ContactPage", id: "ContactUs", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderMenu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [current, setCurrent] = useState("");

  const setCurrentSection = (page) => {
    navigation.map((item) => {
      setCurrent(page);
      if (item.name === page) {
        item.current = true;
      } else {
        item.current = false;
      }
    });
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50 ">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5 ">
            <img
              src={MentalHealthConnectionsLogoGreen}
              onClick={() => setCurrentSection("home")}
              className="max-h-20 w-auto text-primary float-left ml-8"
            />
            {/* <h2 className="mx-10 text-lg font-semibold">
                Mental Health Connections
              </h2> */}
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            id="mobileMenuButton"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon
              id="open_side_menu"
              className="h-6 w-6 text-primary"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <Link
              key={item.name}
              id={item.id}
              to={item.href}
              onClick={() => setCurrentSection(item.name)}
              className={classNames(
                item.current
                  ? "text-primary border-b-2 border-dark-text"
                  : "hover:text-primary",
                "text-lg font-bold leading-7 p-2 text-dark-text  hover:text-primary "
              )}
            >
              {item.name}
            </Link>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="https://byamour.ca/"
            id="loginButton"
            className="text-md font-bold leading-7 p-2 text-dark-text  hover:text-primary rounded-lg"
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-background px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                src={MentalHealthConnectionsLogoGreen}
                onClick={() => setCurrentSection("home")}
                className="h-10 w-auto text-primary float-left"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-dark-text"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    id={item.id}
                    to={item.href}
                    onClick={() => setCurrentSection(item.name)}
                    className={classNames(
                      item.current
                        ? "text-primary border-b-2 border-dark-text"
                        : "hover:text-primary",
                      "-mx-3 block px-3 py-2 text-base font-semibold leading-7 text-dark-text  hover:text-primary"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="py-6">
                <Link
                  to="/ComingSoonPage"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-dark-text hover:bg-gray-50 hover:text-primary"
                >
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
