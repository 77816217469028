import React from "react";
import {
  HiChartBar,
  HiCalendar,
  HiOutlineViewGridAdd,
  HiClipboard,
} from "react-icons/hi";
import nonagon from "../../Images/nonagon/nonagon_green.svg";
import product_logo from "../../Images/product_logo.svg";

import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";
import { LinkIcon } from "@heroicons/react/24/outline";

export default function ProductPage() {
  return (
    <div className="bg-background">
      <HeaderMenu />
      <div className="relative isolate overflow-hidden bg-background px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <div
            className="absolute left-0 top-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:top-0"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-primary-light to-primary opacity-30 sm:left-4 sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute left-0 bottom-0 -z-10 transform-gpu overflow-hidden blur-3xl sm:bottom-0"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-primary-light to-primary opacity-25"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-lg font-semibold leading-7 text-primary">
                  Introducing
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-dark-text sm:text-4xl">
                  Mental Health Connections Self-Prescription
                </h1>
                <p className="mt-6 text-xl leading-8 text-dark-text">
                  Your Mental Health is not a singular item, but rather it is an
                  interconnected and interdependent concept involving multiple
                  areas of health that impact your overall well-being.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img className=" object-fill p-40" src={product_logo} alt="" />
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl text-lg leading-7 text-dark-text lg:max-w-lg">
                <p>
                  We use Health Circles to represent different areas that impact
                  your mental health. Efforts to combine and simultaneously
                  invest in multiple Health Circles allows you to create
                  benefical Mental Health Connections.
                </p>
                <ul
                  role="list"
                  className="mt-8 space-y-8 text-lg text-dark-text"
                >
                  <li className="flex gap-x-3">
                    <HiOutlineViewGridAdd
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-dark-text">
                        Nine clearly defined Health Circles.
                      </strong>{" "}
                      Each Health Circle represents a different area that
                      positively impacts your mental health.
                      <img src={nonagon} className="h-96 m-2 w-auto mx-auto" />
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LinkIcon
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-dark-text">
                        Gain insights into your Mental Health.
                      </strong>{" "}
                      Linking items from different Health Circles lets you
                      create positive Mental Health Connections.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <HiCalendar
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-dark-text">
                        Create calendar events.
                      </strong>{" "}
                      Easily{" "}
                      <span className="font-semibold text-primary">
                        Self-Prescribe
                      </span>{" "}
                      events in your calendar for your Mental Health
                      Connections, set reminders, and invite others to join your
                      event.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <HiChartBar
                      className="mt-1 h-5 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-dark-text">
                        Track your progress over time.
                      </strong>{" "}
                      Note gains and identify areas that you may benefit from
                      focusing more on.
                    </span>
                  </li>
                </ul>
                {/* <p className="mt-8">
                  Et vitae blandit facilisi magna lacus commodo. Vitae sapien
                  duis odio id et. Id blandit molestie auctor fermentum
                  dignissim. Lacus diam tincidunt ac cursus in vel. Mauris
                  varius vulputate et ultrices hac adipiscing egestas. Iaculis
                  convallis ac tempor et ut. Ac lorem vel integer orci.
                </p>
                <h2 className="mt-16 text-2xl font-bold tracking-tight text-light-text">
                  No server? No problem.
                </h2>
                <p className="mt-6">
                  Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam
                  consequat in. Convallis arcu ipsum urna nibh. Pharetra,
                  euismod vitae interdum mauris enim, consequat vulputate nibh.
                  Maecenas pellentesque id sed tellus mauris, ultrices mauris.
                  Tincidunt enim cursus ridiculus mi. Pellentesque nam sed
                  nullam sed diam turpis ipsum eu a sed convallis diam.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterWebsite />
    </div>
  );
}
